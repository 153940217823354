@import url('https://fonts.googleapis.com/css?family=Philosopher');
@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }
 

 .Footer{
   position: absolute;
   bottom: 0;
 }

 .App{
  background:center/cover fixed;
  background-attachment: fixed;
 }

 .background1 {
  background-image: url('/src/components/images/scotland\ photo.jpg');
}


