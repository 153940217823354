@import url(https://fonts.googleapis.com/css?family=Philosopher);
@import url(https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext);
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }
 

 .Footer{
   position: absolute;
   bottom: 0;
 }

 .App{
  background:center/cover fixed;
  background-attachment: fixed;
 }

 .background1 {
  background-image: url("/static/media/scotland photo.7d9d088e.jpg");
}



.mynavbar {
    background-color: #ffffff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0px 5px 5px rgba(20, 17, 17, 0.152);
  }
  
  .mynavbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .mynavbar-logo {
    color: #000000;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .mynavbar-logo:hover{
    color:rgb(0, 0, 0);
  }

  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .mynav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .mynav-item {
    height: 80px;
  }
  
  .mynav-links {
    height: 80px;
    color: #000000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .mynav-links:hover {
    border-bottom: 1px solid #000000;
    transition: all 0.2s ease-out;
    color: rgb(0, 0, 0);
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .mynav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .myNavbarItems {
      position: relative;
    }
  
    .mynav-menu {
      display: block;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .mynav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .mynav-links {
      height: 80px;
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .mynav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .mynavbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translate(25%, 50%);
              transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .mynav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .mynav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }

.hero-container{

    height:  70vh;
    width:  100%;
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit:  contain;
}


.hero-container > p {
    align-self: center;
    margin-top: 8px;
    color: rgb(0, 0, 0);
    font-size: 40px;
    
    margin-bottom: 100px;
    /* font-family: 'Montserrat', sans-serif; */
    font-weight: 400;

}

.btn{
    width: 500px;
}

.btn a{
    justify-content: center;
    display: flex;
    color: black;
    text-decoration: none;
    
}


.hero-btns {
    margin-top: 32px;
    color: black;
  }
  
.hero-btns .btn {
    margin: 6px;
    color: black;
  }

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size:  70px;
        margin-top:  -100px;
    }    
    .hero-container > p {
        font-size:  30px;
    }

    .btn-mobileP{
        display: block;
        text-decoration: none;
    }
    .btn{
        width:400px;
    }
    
}

@media screen and (max-width: 790px){
    .hero-container > p {
        font-size:  25px;
    }
    .btn {
        width: 380px;
    }
}

@media screen and (max-width: 670px ) {
    .hero-container > p {
        font-size:  18px;
    }
}

.body {
    background-color: #ffffff;
    justify-content: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
    padding-bottom: 1rem ;
    border-radius: 10px;
    border-color: #6e6e6e;
    border-style: solid;
    border-width: 0.5px;
    max-width: 300px;
    box-shadow: 10px 5px 10px rgba(22, 19, 19, 0.216);

}

.Con_title {
    color: rgb(0, 0, 0);
    text-align: center;
    padding-top: 10px;
}


#entery{
    background-color: #ebebeb63;
    border-radius: 25px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    margin: 3px;
    white-space: nowrap;
    border: 3px solid rgb(96, 104, 123);
    color: black;
    font-size: 13px;
}

/* #entery:hover{
    background-color: rgba(72, 72, 72, 0.626);
    transition: all 0.1s ease-in-out;
    color: white;
} */

#title{
    font-weight: 300;
}
#body {
    background-color: white;
    padding-bottom: 1rem ;
    border-radius: 10px;
    border-color: #6e6e6e;
    border-style: solid;
    border-width: 0.5px;    
    margin-top: 25px;
    margin-bottom: 25px;
    min-width: 70%;
    transition: all 0.3s linear;
    box-shadow: 10px 5px 10px rgba(22, 19, 19, 0.216);
}

#projectTitle{
    margin-top: 10px;
    padding-left: 20px;
    transition: all 0.3s linear;
}

#projectInfo, #projectLanguages, #projectTechnologies, #projectLink{
    padding-left:20px;
    transition: all 0.3s linear;
}

#overRide{
    color: black;
    text-decoration: none;
}

#overRide:hover{
    color: black;
    text-decoration: none;
}


#app{
    width: 136px;
    height: 200px;
    transition: all 0.3s linear;
}

#website{
    height: 143.6px;
    width: 300px;
    transition: all 0.3s linear;
}

#projectIMG{
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
    border: solid grey 1px;
}

#projectLink{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#body:hover{
    min-width: 100%;
}   

#body:hover #projectTitle{
    font-size: 3rem;
}

#body:hover #projectInfo{
    font-size: 1.25rem;
    margin-top: 30px;
}

#body:hover #projectLanguages{
    font-size: 1.25rem;
    margin-top: 20px;
}

#body:hover #projectTechnologies{
    font-size: 1.25rem;
    margin-top: 20px;
}

#body:hover #projectLink{
    font-size: 1.25rem;
    margin-top: 20px;
}

#body:hover #app{
    width:250px;
    height:400px;
}

#body:hover #website{
    width:600px;
    height:287px;
}


.Containers{
    background-color: hsl(0deg 0% 95%);
}

.codeCons{
    margin-top: 50px;
}

#header{
    margin-top: 0.5rem;
}
.fullpage{
    justify-content: center;
    height:  92vh;
    width:  100%;
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit:  contain;
}

.space {
margin-bottom: 10px;

}

.head{
    margin-top: 20px;
}

.h1 {
    /* margin-bottom: auto; */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.profilePic{
    width: 165px;
    height: 250px;
    box-shadow: 9px 9px 7px rgb(0 0 0 / 23%);
    border-radius: 5px;
    margin-top: 5px;
}

.moreinfo {
    
    justify-self: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    width: 600px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    border-style: none;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 17, 17, 0.152);
    flex-direction: column;
    
}

.moreinfo > p{
    margin: 20px;
    color: rgb(68, 68, 68);
}

.myInfo{
    margin: 20px;
}





.con{
    margin: 50px;
}



@media screen and (min-width: 1100px) {
    .Containers{
        align-items: center;
        display: flex;
        
    }

    .con1 {
        padding-right: 1rem;
        display: inline-flex;

    }

    .con2 {
        padding-left: 1rem;
        display: inline-flex;
    }

    .work{
        padding: 2rem;
    }

}
.CodeContainer{
    margin-top: 235px;
}
.whole_page{
    background: url("/static/media/scotland photo.7d9d088e.jpg") center center/cover
    no-repeat;
    height:  70vh;
    width:  100%;
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit:  contain;
}

.resume{
    margin-left: 5%;
    margin-right: 5%;
    height: 50px;
    margin-bottom: 25px;
    border-radius: 10px;
   
    
}

.obj {
    width: auto;
    height: 100%;
    padding: 13px;
    box-shadow: 0 6px 20px #9f38ff2b; 
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(2, 4, 8, 0.017));
}

@media screen and (min-width: 1100px){
    .resume{
        margin-left: 24%;
        margin-right: 24%
    }
}
.footer-container {
  background-color: #242424;
  padding: 2.5rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
    display: flex;
    flex-direction: column;
    margin: 16px;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 10px;
    color: rgb(255, 255, 255);
  }

  .footer-link-wrapper h2 {
    margin-bottom: 16px;
  }

  .phone_number {
    margin-top: 10px;
  }
  
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    width: 100%;   
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items:center;
    width: 90%;
    margin: 0px auto 0 auto;
    text-align: center;
    flex-direction: row;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    width: 80px;
 
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    
  }
  
  .web-rights {
    color: #ffff;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    
    
  }
  
 @media screen and (max-width: 650px) {
  .social-media-wrap{
    flex-direction: column;
  }
  
 }
