.footer-container {
  background-color: #242424;
  padding: 2.5rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
    display: flex;
    flex-direction: column;
    margin: 16px;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 10px;
    color: rgb(255, 255, 255);
  }

  .footer-link-wrapper h2 {
    margin-bottom: 16px;
  }

  .phone_number {
    margin-top: 10px;
  }
  
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    width: 100%;   
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items:center;
    width: 90%;
    margin: 0px auto 0 auto;
    text-align: center;
    flex-direction: row;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    width: 80px;
 
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    
  }
  
  .web-rights {
    color: #ffff;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    
    
  }
  
 @media screen and (max-width: 650px) {
  .social-media-wrap{
    flex-direction: column;
  }
  
 }