.con{
    margin: 50px;
}



@media screen and (min-width: 1100px) {
    .Containers{
        align-items: center;
        display: flex;
        
    }

    .con1 {
        padding-right: 1rem;
        display: inline-flex;

    }

    .con2 {
        padding-left: 1rem;
        display: inline-flex;
    }

    .work{
        padding: 2rem;
    }

}