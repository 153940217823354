#body {
    background-color: white;
    padding-bottom: 1rem ;
    border-radius: 10px;
    border-color: #6e6e6e;
    border-style: solid;
    border-width: 0.5px;    
    margin-top: 25px;
    margin-bottom: 25px;
    min-width: 70%;
    transition: all 0.3s linear;
    box-shadow: 10px 5px 10px rgba(22, 19, 19, 0.216);
}

#projectTitle{
    margin-top: 10px;
    padding-left: 20px;
    transition: all 0.3s linear;
}

#projectInfo, #projectLanguages, #projectTechnologies, #projectLink{
    padding-left:20px;
    transition: all 0.3s linear;
}

#overRide{
    color: black;
    text-decoration: none;
}

#overRide:hover{
    color: black;
    text-decoration: none;
}


#app{
    width: 136px;
    height: 200px;
    transition: all 0.3s linear;
}

#website{
    height: 143.6px;
    width: 300px;
    transition: all 0.3s linear;
}

#projectIMG{
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
    border: solid grey 1px;
}

#projectLink{
    width: fit-content;
}

#body:hover{
    min-width: 100%;
}   

#body:hover #projectTitle{
    font-size: 3rem;
}

#body:hover #projectInfo{
    font-size: 1.25rem;
    margin-top: 30px;
}

#body:hover #projectLanguages{
    font-size: 1.25rem;
    margin-top: 20px;
}

#body:hover #projectTechnologies{
    font-size: 1.25rem;
    margin-top: 20px;
}

#body:hover #projectLink{
    font-size: 1.25rem;
    margin-top: 20px;
}

#body:hover #app{
    width:250px;
    height:400px;
}

#body:hover #website{
    width:600px;
    height:287px;
}

