
.hero-container{

    height:  70vh;
    width:  100%;
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit:  contain;
}


.hero-container > p {
    align-self: center;
    margin-top: 8px;
    color: rgb(0, 0, 0);
    font-size: 40px;
    
    margin-bottom: 100px;
    /* font-family: 'Montserrat', sans-serif; */
    font-weight: 400;

}

.btn{
    width: 500px;
}

.btn a{
    justify-content: center;
    display: flex;
    color: black;
    text-decoration: none;
    
}


.hero-btns {
    margin-top: 32px;
    color: black;
  }
  
.hero-btns .btn {
    margin: 6px;
    color: black;
  }

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size:  70px;
        margin-top:  -100px;
    }    
    .hero-container > p {
        font-size:  30px;
    }

    .btn-mobileP{
        display: block;
        text-decoration: none;
    }
    .btn{
        width:400px;
    }
    
}

@media screen and (max-width: 790px){
    .hero-container > p {
        font-size:  25px;
    }
    .btn {
        width: 380px;
    }
}

@media screen and (max-width: 670px ) {
    .hero-container > p {
        font-size:  18px;
    }
}
