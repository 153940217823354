.fullpage{
    justify-content: center;
    height:  92vh;
    width:  100%;
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit:  contain;
}

.space {
margin-bottom: 10px;

}

.head{
    margin-top: 20px;
}

.h1 {
    /* margin-bottom: auto; */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.profilePic{
    width: 165px;
    height: 250px;
    box-shadow: 9px 9px 7px rgb(0 0 0 / 23%);
    border-radius: 5px;
    margin-top: 5px;
}

.moreinfo {
    
    justify-self: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    width: 600px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    border-style: none;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 17, 17, 0.152);
    flex-direction: column;
    
}

.moreinfo > p{
    margin: 20px;
    color: rgb(68, 68, 68);
}

.myInfo{
    margin: 20px;
}




